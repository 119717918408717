import {Component, Input} from '@angular/core';
import { NavigationService } from 'src/app/data/services/navigation.service';
import {ExternalOffer} from '../../data/models/external-offer.model';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html'
})
export class ImageSliderComponent {
  @Input() externalOffers: ExternalOffer[] = [];

  constructor(public navigationService: NavigationService) {
    this.externalOffers = this.externalOffers.filter(offer => offer.imageUrl != null && offer.hasImage);
  }
}
