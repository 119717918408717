<ngb-carousel>
  <ng-template ngbSlide *ngFor="let exOf of externalOffers">
    <div class="picsum-img-wrapper">
      <ng-container *ngIf="exOf?.imageUrl">
        <img alt="{{exOf.header}}" [src]="exOf?.imageUrl" class="image"
             (click)="navigationService.navigateExtern(exOf.externalURL)"/>
      </ng-container>
    </div>
    <div class="carousel-caption">
      <h3 (click)="navigationService.navigateExtern(exOf.externalURL)">{{exOf.header}}</h3>
      <h2 (click)="navigationService.navigateExtern(exOf.externalURL)">{{exOf.description}}</h2>
    </div>
  </ng-template>
</ngb-carousel>
