<app-general-errors [errors]="errorsArr"></app-general-errors>

<div id="complaintEdit">
  <mat-stepper linear #stepper [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="complaintForm" editable>
        <span class="info" *ngIf="messagesPresent">
          <mat-icon class="info-icon">info-circle</mat-icon>
          <span class="info-text">
            <h6>{{messagesPresentText}}</h6>
            <h6>{{messageText}}</h6>
          </span>
        </span>
      <form [formGroup]="complaintForm" class="mt-3">
        <ng-container *ngIf="!isMobile">
          <ng-template matStepLabel>
            <div [innerHTML]="translationService.getTranslation('step_complaint')"></div>
          </ng-template>
        </ng-container>
        <div *ngIf="isMobile" class="mx-1">
          <h2 [innerHTML]="translationService.getTranslation('step_complaint')"></h2>
        </div>
        <mat-form-field>
          <mat-label [innerHTML]="translationService.getTranslation('complaint_reason')"></mat-label>
          <mat-select formControlName="reason" required [value]="complaintForm.get('reason').value"
                      [compareWith]="compareBaseData">
            <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason.description}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="complaintForm.get('reason').hasError('message')">{{complaintForm.get('reason').getError('message')}}
          </mat-error>
        </mat-form-field>
        <div>
          <button mat-raised-button color="primary" matStepperNext
                  (click)="loadDueDates(complaintForm.get('reason')?.value?.key)" [innerHTML]="translationService.getTranslation('button_next')">
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="complaintDueDatesForm" editable
              *ngIf="complaintDays?.complaintDaysArray?.length > 1 || isLoadingDueDates">
      <div *ngIf="isLoadingDueDates; else complaintDueDateForm" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <ng-template #complaintDueDateForm>
        <form [formGroup]="complaintDueDatesForm" class="mt-3">
          <ng-container *ngIf="!isMobile">
            <ng-template matStepLabel>
            <div [innerHTML]="translationService.getTranslation('step_complaintVariantDate')"></div></ng-template>
          </ng-container>

          <div *ngIf="isMobile" class="mx-1">
            <h2 [innerHTML]="translationService.getTranslation('step_complaintVariantDate')"></h2>
          </div>
          <div *ngIf="complaintDays?.complaintDaysArray?.length > 0; else noComplaintDays">
            <mat-checkbox *ngIf="complaintDays?.complaintDaysArray?.length > 1"
                          class="example-margin"
                          [checked]="allComplete"
                          [indeterminate]="someComplete()"
                          (change)="setAll($event.checked)">
              {{complaintDays.name}}
            </mat-checkbox>
            <ul class="complaintDays">
              <li formArrayName="dates"
                  *ngFor="let complaintDay of complaintDueDatesFormDateArray.controls; let i = index">
                <mat-checkbox [formControlName]="i" (change)="changeComplaintDay(i)">
                  {{complaintDays?.complaintDaysArray[i]?.name | date: translationService.getTranslation('dateFormat')}}
                </mat-checkbox>
              </li>
            </ul>
            <div>
              <button class="me-3" mat-raised-button
                      matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')">
              </button>
              <button mat-raised-button color="primary" matStepperNext
                      (click)="loadComplaintActionsByCodeAndDate(complaintForm.get('reason')?.value?.key)" [innerHTML]="translationService.getTranslation('button_next')">
              </button>
            </div>
          </div>
          <ng-template #noComplaintDays>
            <div [innerHTML]="translationService.getTranslation('complaint_no_deliverydays_found')"></div>
          </ng-template>
        </form>
      </ng-template>
    </mat-step>
    <!-- Kompensation (opt.) -->
    <mat-step [stepControl]="complaintCompensationForm" editable *ngIf="compensationStep">
      <form [formGroup]="complaintCompensationForm" class="mt-3">
        <ng-container *ngIf="!isMobile">
          <ng-template matStepLabel> <div [innerHTML]="translationService.getTranslation('step_complaintCompensation')"></div></ng-template>
        </ng-container>
        <div *ngIf="isMobile" class="mx-1">
          <h2 [innerHTML]="translationService.getTranslation('step_complaint_compensation')"></h2>
        </div>

        <mat-form-field>
          <mat-label [innerHTML]="translationService.getTranslation('complaint_compensation')"></mat-label>
          <mat-select formControlName="compensation" required
                      [value]="complaintCompensationForm.get('compensation')">
            <mat-option *ngFor="let reason of complaintActions" [value]="reason">{{reason.description}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="complaintCompensationForm.get('compensation').hasError('message')">
            {{complaintCompensationForm.get('compensation').getError('message')}}
          </mat-error>
        </mat-form-field>

      <div>
        <button class="me-3" mat-raised-button
                matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')"></button>
        <button mat-raised-button color="primary" matStepperNext
                (click)="getPriceForComplaint()" [innerHTML]="translationService.getTranslation('button_next')"></button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="complaintMemoForm" editable *ngIf="getComplaintCode().withMemoFlag">
    <form [formGroup]="complaintMemoForm" class="mt-3">
      <ng-container *ngIf="!isMobile">
        <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_complaintCompensation')"></div></ng-template>
      </ng-container>
      <div *ngIf="isMobile" class="mx-1">
        <h2 [innerHTML]="translationService.getTranslation('step_complaint_compensation')"></h2>
      </div>

      <mat-form-field>
        <mat-label [innerHTML]="translationService.getTranslation('complaint_reasonMemo')"></mat-label>
        <textarea maxlength="65535" matInput cdkAutosizeMinRows="3" cdkTextareaAutosize formControlName="memo"
                  [value]="complaintMemoForm.get('memo').value" #reasonMemo></textarea>
        <app-signs-counter *ngIf="reasonMemo.value.length > 0 && reasonMemo.value.length <= reasonMemo.maxLength" [actual]="reasonMemo.value.length" [possible]="reasonMemo.maxLength"></app-signs-counter>
        <mat-error
                *ngIf="complaintMemoForm.get('memo').hasError('message')">{{complaintMemoForm.get('memo').getError('message')}}</mat-error>
      </mat-form-field>

        <div>
          <button class="me-3" mat-raised-button
                  matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')">
          </button>
          <button mat-raised-button color="primary" matStepperNext
                  (click)="getPriceForComplaint()" [innerHTML]="translationService.getTranslation('button_next')">
          </button>
        </div>
      </form>
    </mat-step>

  <mat-step>
    <ng-container *ngIf="!isMobile">
      <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_summary')"></div></ng-template>
    </ng-container>
    <div *ngIf="isMobile" class="mx-1">
      <h2 [innerHTML]="translationService.getTranslation('step_summary')"></h2>
    </div>
    <div class="mt-3">
      <h5 [innerHTML]="translationService.getTranslation('step_complaint')"></h5>
      <div class="container-fluid p-0">
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('date')"></div>
          <div class="col-6">
            <ul class="ps-0">
              <li *ngFor="let day of getSelectedDates()"> {{day | date: translationService.getTranslation('dateFormat')}} </li>
            </ul>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_reason')"></div>
          <div class="col-6">{{complaintForm.get('reason')?.value?.description}}</div>
        </div>
        <div class="row m-0" *ngIf="complaintMemoForm.get('memo').value !== ''">
          <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_reasonMemo')"></div>
          <div class="col-6">{{complaintMemoForm.get('memo').value}}</div>
        </div>
      </div>
      <mat-divider class="mb-1 mt-3"></mat-divider>

        <ng-container *ngIf="compensationStep || complaintCompensationForm.get('compensation').value?.code ">
          <h5 [innerHTML]="translationService.getTranslation('step_complaintCompensation')"></h5>
          <div class="container-fluid p-0">
            <div class="row m-0">
              <div class="col-6" [innerHTML]="translationService.getTranslation('complaint_compensation')"></div>
              <div class="col-6">{{complaintCompensationForm.get('compensation').value.description}}</div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="isSaving" style="text-align: center;">
          <app-loading-spinner></app-loading-spinner>
        </div>

        <br/>
        <div>
          <button class="me-3" mat-raised-button
                  matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')">
          </button>
          <button mat-raised-button color="primary" [hidden]="isSaving" (click)="onSend()" [innerHTML]="translationService.getTranslation('button_send')">
          </button>
        </div>
      </div>
    </mat-step>

  </mat-stepper>
</div>
<div class="mt-3 float-end">
  <button *ngIf="showBackToOverviewButton"
          mat-raised-button color="primary" (click)="backToOverview()" [innerHTML]="translationService.getTranslation('button_back_to_overview')">
  </button>
</div>
