import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HupSubscription} from '../../../data/models/subscription.model';
import {TranslationService} from '../../../core/services/translation.service';
import {CustomValidators} from '../../../core/validators';
import {HttpClient} from '@angular/common/http';
import {Payment} from '../../../data/models/payment.model';
import {PaymentService} from '../../../data/services/payment.service';
import {ActivatedRoute} from '@angular/router';
import {ErrorService} from '../../../core/components/general-errors/error.service';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {NavigationCommand, NavigationService} from '../../../data/services/navigation.service';
import {SnackbarService} from "../../../core/services/snackbar.service";

@Component({
  selector: 'app-payment-edit',
  templateUrl: './payment-edit.component.html',
  styleUrls: ['./payment-edit.component.scss']
})
export class PaymentEditComponent implements OnInit {
  paymentEditForm: FormGroup;
  payment: Payment;
  subs: HupSubscription[];
  isLoadingPayment: boolean = false;
  errorsArr: string[] = [];
  isMobile: boolean = false;
  isSaving: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private http: HttpClient,
              private paymentService: PaymentService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private errorService: ErrorService,
              private subscriptionService: HupSubscriptionsService,
              public translationService: TranslationService,
              private snackBar: SnackbarService,) {
  }

  ngOnInit(): void {
    this.isLoadingPayment = true;

    this.subscriptionService.getSubscriptions().subscribe(subs => {
      this.subs = subs;
    });

    this.paymentEditForm = this.formBuilder.group({
      bank: this.formBuilder.group({
        accountHolder: [''],
        backendId: [''],
        bankAccountNo: [''],
        bankCode: [''],
        bankName: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
        subId: [''], // ist die nicht falsch verschachtelt?
        swiftIBAN: ['', Validators.required],
        swiftBIC: [{value: this.translationService.getTranslation('filled_automatically'), disabled: true}],
        webId: [''],
        creditCard: this.formBuilder.group({
          backendId: [''],
          ccCheckNo: [''],
          ccNo: [''],
          ccValidMon: [''],
          ccValidYear: [''],
          creditCard: [''],
          webId: [''],
        }),
      }),
      validDate: this.formBuilder.group({
        validFrom: [''],
        validUntil: ['']
      }),
    });

    this.paymentEditForm.get('bank.swiftIBAN').setAsyncValidators(CustomValidators.validateIBAN(
      this.http,
      this.translationService,
      this.paymentEditForm.get('bank.swiftBIC'),
      this.paymentEditForm.get('bank.bankName')
    ));

    this.route.paramMap.subscribe((params) => {
      this.paymentService.getPayment(params.get('paymentWebId'), params.get('paymentBackendId')).subscribe(payment => {
        this.isLoadingPayment = false;
        this.payment = payment;

        this.paymentEditForm.get('bank.swiftBIC').patchValue(payment.bank.swiftBIC);
        this.paymentEditForm.get('bank.swiftIBAN').patchValue(payment.bank.swiftIBAN);
      }, error => {
        this.isLoadingPayment = false;
        this.errorService.handleAPIError([this.paymentEditForm], error.error, this.errorsArr);
        this.isSaving = false;
      });
    });
  }

  onSendPayment(): void {
    if (this.paymentEditForm.valid) {
      this.isSaving = true;
      this.isLoadingPayment = true;
      this.paymentEditForm.get('bank.swiftBIC').enable();
      const data = this.paymentEditForm.value;
      this.paymentEditForm.get('bank.swiftBIC').disable();
      data.backendId = this.payment.backendId;
      data.webId = this.payment.webId;

      data.validDate.validFrom = new Date();

      this.paymentService.sendPaymentEdit(data).subscribe({
        next: (res) => {
          this.navigationService.navigateTo(NavigationCommand.PAYMENT).then((navigated) => {
            if (navigated) {
              this.snackBar.openSnackbar(this.translationService.getTranslation("saved"));
            }
          });
        },
        error: (error) => {
          this.errorService.handleAPIError([this.paymentEditForm], error.error, this.errorsArr);
          console.log('Error: ', error);
          this.isSaving = false;
        },
        complete: () => {
          this.isLoadingPayment = false;
          this.isSaving = false;
        }
      });
    }
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }
}
