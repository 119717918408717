<div [className]="cssPropertiesEnum.bodyClasses|cssProperty: vHostService">
  <div class="row">
    <ng-container *ngIf="hideNavigation; else withNavigation">
      <div class="col-md-12 mt-3">
        <div class="container-fluid p-0">
          <app-breadcrumb *ngIf="useBreadCrumb"></app-breadcrumb>
          <div style="width:100%">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #withNavigation>
      <div [className]="horizontalNavigation ? 'col-md-12' : 'col-md-3'">
        <app-navigation [horizontalNavigation]="horizontalNavigation" *ngIf="!useBreadCrumb"></app-navigation>
      </div>
      <div [className]="horizontalNavigation ? 'col-md-12 mt-3' : 'col-md-9'">
        <div class="container-fluid p-0">
          <app-breadcrumb *ngIf="useBreadCrumb"></app-breadcrumb>
          <div style="width:100%">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
