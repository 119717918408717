import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {TranslationService} from '../../../services/translation.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../login.service';
import {SsoConfiguration} from "../sso-configuration";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBoxComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  error: string;

  ssoConfiguration: SsoConfiguration;

  private static EventListenerAdded = false;
  private static DialogRef : MatDialogRef<LoginBoxComponent> = null;

  constructor(@Inject(DOCUMENT) private document: Document,
              public translationService: TranslationService,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<LoginBoxComponent>,
              private loginService: LoginService,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: {url: string}
              ){
    LoginBoxComponent.DialogRef = dialogRef;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

    this.loginService.getSsoConfiguration().subscribe( ssoConf => {
      this.ssoConfiguration = ssoConf;
    });

    if (!LoginBoxComponent.EventListenerAdded) {
      LoginBoxComponent.EventListenerAdded = true;
      window.addEventListener(
        "message",
        (event) => {
          const alreadyLoggedIn = event.data.code === 2000;
          const justLoggedIn = typeof event.data.code == 'string' && event.data.code.match(/^[0-9a-f]{16,}$/);
          if (alreadyLoggedIn || justLoggedIn) {
            this.onClose();
            this.loginService.checkStatus();
          } else {
            this.loginService.resetData();
          }
        },
        false,
      );
    }
  }

  hassoLoginBoxSrc(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl([
      this.ssoConfiguration.ssoHost + '/auth/authorize',
      '?client_id=' + this.ssoConfiguration.clientId,
      '&return_uri=' + encodeURIComponent(this.ssoConfiguration.returnUrl),
      '&responsetype=event',
      '&domain=' + encodeURIComponent(window?.location.origin),
      '&ajaxMode=1'
    ].join(''));
  }

  onLogin(): void {
    if (this.loginForm.valid) {
      this.loading = true;

      if (!this.data || !this.data.url) {
        this.data = {url: ''};
      }

      this.loginService.login(this.loginForm.get('email').value, this.loginForm.get('password').value, this.data.url).subscribe((res) => {
        if (res && res.token !== null && res.token !== 0 && res.status !== 'error') {
          this.onClose();
        } else {
          if (res?.message) {
            this.error = res.message;
          } else {
            this.error = 'Bei der Anmeldung ist ein Fehler aufgetreten.'
          }
          this.loading = false;
        }
      }, httpError => {
        this.error = httpError.error.message;
        this.loading = false;
      });
    }
  }

  onClose(): void {
    LoginBoxComponent.DialogRef?.close();
  }
}
