import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthResponseData} from "../login/auth-response-data.model";
import {TranslationService} from "../../services/translation.service";

@Component({
  selector: 'app-login-formular',
  templateUrl: './login-formular.component.html',
  styleUrls: ['./login-formular.component.scss']
})
export class LoginFormularComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  error: string;
  returnUrl: string = null;
  isAuthenticated: boolean = false;
  authSub: Subscription;
  authData: AuthResponseData = null;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    public translationService: TranslationService
  ) {
    this.route?.queryParams?.subscribe((params) => {
      if (params && params.returnUrl) {
        this.returnUrl = params.returnUrl;
        this.handleRedirect();
      }
    });

    this.authSub = this.loginService?.getAuthData()?.subscribe((authData) => {
      this.authData = authData;
      this.isAuthenticated = !!authData;

      this.handleRedirect();
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder?.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  onLogin(): void {
    if (this.loginForm?.valid) {
      this.loading = true;
      this.loginService.login(this.loginForm.get('email').value, this.loginForm.get('password').value, this.returnUrl).subscribe((res) => {
        if (res && res.token !== 0 && res.status !== 'error') {
        } else {
          this.error = res.message;
          this.loading = false;
        }
      }, error => {
        this.error = error.error;
        this.loading = false;
      });
    }
  }

  private handleRedirect(): void {
    if (this.isAuthenticated && this.returnUrl) {
      this.router.navigate([this.returnUrl],  {queryParamsHandling: "preserve"});
    }
  }

}
