<ng-container *ngIf="send">
  <div [innerHTML]="translationService.getTranslation('contact_send')"></div>
</ng-container>
<ng-container *ngIf="!send">
  <div class="row">
    <div class="col-12">
      <h4 [innerHTML]="translationService.getTranslation('contact_intro')"></h4>
    </div>
    <div class="col-12" [innerHTML]="translationService.getTranslation('contact_description')">
    </div>
  </div>

  <app-general-errors [errors]="errorsArr"></app-general-errors>

  <form [formGroup]="contactForm">
    <div class="row">
      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('contact_type')"></mat-label>
        <mat-select formControlName="reason" required>
          <mat-option *ngFor="let contactType of contactTypes"
                      [value]="contactType.key">{{contactType.description}}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="contactForm.get('reason').hasError('message')">{{contactForm.get('reason').getError('message')}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('contact_message')"></mat-label>
        <textarea matInput cdkAutosizeMinRows="5" cdkTextareaAutosize formControlName="memo" required></textarea>
        <mat-error
          *ngIf="contactForm.get('memo').hasError('message')">{{contactForm.get('memo').getError('message')}}</mat-error>
      </mat-form-field>

      <div *ngIf="isSaving" style="text-align: center;">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <div class="col-12">
        <button [hidden]="isSaving" mat-raised-button color="primary"
                (click)="onSendContact()" [innerHTML]="translationService.getTranslation('button_send')"></button>
      </div>
    </div>
  </form>
</ng-container>
