<div class="row" id="complaintNoSubscriptionHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('complaint_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('complaint_description')">
  </div>
</div>
<div class="row">
  <div class="col-12 complaintNoSubscriptionErrors">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div id="complaintNoSubscription">
      <app-client-search (validateButtonClick)="loadOrdersForClientno($event)" [isLoading]="isLoadingSub">
        <mat-error *ngIf="clientNoForm.get('clientNo').hasError('message')">{{clientNoForm.get('clientNo').getError('message')}}</mat-error>
      </app-client-search>
      <div *ngIf="noOrdersFound" class="mb-3"
           [innerHTML]="translationService.getTranslation('complaint_cannot_find_clientno')">
      </div>
      <ng-container *ngIf="!isLoadingComp && !isLoadingSub && orders.length > 0">
        <mat-card appearance="outlined" *ngFor="let sub of orders; let i = index" class="mt-3">
          <div class="row m-3">
            <div class="col-6">
              <mat-card-title>{{sub.productDescription}}</mat-card-title>
              <mat-card-subtitle [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.subId ?? sub.backendId + '<br>' +
                                              translationService.getTranslation('order_edition') + ': ' + sub.variantDescription ?? sub.variantCode">
              </mat-card-subtitle>
            </div>
            <div class="col-6">
              <mat-card-actions>
                <button mat-raised-button color="primary"
                        (click)="onNewComplaint(sub)" [innerHTML]="translationService.getTranslation('button_new_complaint')"></button>
              </mat-card-actions>
            </div>
          </div>

        </mat-card>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="showContactFormOnComplaintViewWithoutLogin">
  <div class="row mt-3 mb-3">
    <div class="col-12 mb-2" [innerHTML]="translationService.getTranslation('complaint_noClientno')">
    </div>
  </div>
  <button mat-raised-button
          (click)="navigateToContact()" [innerHTML]="translationService.getTranslation('complaint_ToContactForm')"></button>
</div>
