import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../../core/services/translation.service';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {BaseDataService} from '../../../core/services/base-data.service';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../../core/services/config.service";
import {HupSubscription} from "../../../data/models/subscription.model";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";

@Component({
  selector: 'app-complaint-wihtout-login',
  templateUrl: './complaint-wihtout-login.component.html',
  styleUrls: ['./complaint-wihtout-login.component.scss']
})
export class ComplaintWihtoutLoginComponent implements OnInit {
  errorsArr: string[] = [];
  isMobile: boolean = false;
  clientNoForm: FormGroup;
  orderSelectForm: FormGroup;
  orders: HupSubscription[] = [];
  isLoadingComp: boolean = false;
  isLoadingSub: boolean = false;
  noOrdersFound: boolean = false;
  showContactFormOnComplaintViewWithoutLogin: boolean = true;

  constructor(
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    private subscriptionService: HupSubscriptionsService,
    private baseDataService: BaseDataService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private configService: ConfigService,
  ) {
    this.clientNoForm = this.formBuilder.group({
      clientNo: ['', Validators.required]
    });

    this.configService.loadConfig('showContactFormOnComplaintViewWithoutLogin').subscribe(config => {
      if( config) {
        this.showContactFormOnComplaintViewWithoutLogin = (config.value === 'true');
      }
    });
  }

  ngOnInit(): void {
  }

  onSend(): void {

  }

  loadOrdersForClientno(clientNo: number): void {
    this.isLoadingSub = true;
    this.orders = [];
    this.noOrdersFound = false;
    if (clientNo && Number(clientNo) > 0) {
      this.subscriptionService.getSubscriptionsUnauthorized(clientNo).subscribe(orders => {
        this.isLoadingSub = false;
        if (orders.length === 1 && orders[0]) {
          this.onNewComplaint(orders[0]);
        } else if (orders.length > 1) {
          orders.forEach(order => {
            this.baseDataService.handleUnauthorizedOrder(order);
            this.orders.push(order);
          });
        } else {
          this.noOrdersFound = true;
        }
      });
    }
  }

  onNewComplaint(subscription: HupSubscription): void {
    this.navigationService.navigateTo(NavigationCommand.COMPLAINTWITHPUTLOGINNEW, [subscription.backendId + '', subscription.userBackendId + '', 'true', 'true', (subscription.offer.offerType.ePaper ? 'false' : 'true')]);
  }

  navigateToContact(): void {
    this.navigationService.navigateTo(NavigationCommand.CONTACT);
  }

}
