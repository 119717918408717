import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-signs-counter',
  templateUrl: './signs-counter.component.html',
  styleUrls: ['./signs-counter.component.css']
})
export class SignsCounterComponent {
  @Input() actual: number;
  @Input() possible: number;
}
