import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslationService} from "../../core/services/translation.service";
import {ConfigService} from "../../core/services/config.service";
import {FormControl, FormGroup} from "@angular/forms";
import {HupSubscriptionsService} from "../../data/services/hup-subscriptions.service";
import {AddressListItem} from "../../data/models/address-list.model";
import {LocaleService} from "../../core/services/locale.service";
import {Address} from "../../data/models/address.model";
import {Person} from "../../data/models/person.model";
import {HouseNoPipe} from "../../core/pipes/houseno.pipe";

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.css'],
  providers: [HouseNoPipe]
})
export class ClientSearchComponent {
  @Output() validateButtonClick = new EventEmitter<number>();
  clientno: number;
  zipCode: string;
  houseNumber: string;
  validationFactors: string[];
  clientSelected: boolean = false;
  inputInvalid: boolean = false;
  zipCodeNlValid: boolean = false;
  houseNoNlValid: boolean = false;
  validationIsActive: boolean = true;
  onlyOneResult: boolean = true;
  clientnoNotFound: boolean = false;
  validationForm: FormGroup;
  customer: AddressListItem;
  customers: AddressListItem[];
  @Input() isLoading: boolean = false;
  defaultCountry: string;

  constructor(
    public translationService: TranslationService,
    private subscriptionService: HupSubscriptionsService,
    private configService: ConfigService,
    private localeService: LocaleService,
    private houseNoPipe: HouseNoPipe
  ) {
    const locale = this.localeService.getDefaultCountry();
    this.defaultCountry = locale.split('_')[0];

    this.configService.loadConfig('client.validation-factors').subscribe(factors => {
      if (factors.value != '') {
        this.validationFactors = ((factors.value).replace(/ /g, "")).split(",");
      } else {
        this.validationIsActive = false;
      }
    });

    this.validationForm = new FormGroup<any>({
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      street: new FormControl(''),
      zipcode: new FormControl(''),
      city: new FormControl(''),
      houseno: new FormControl('')
    });
  }

  loadClient() {
    this.isLoading = true;
    if (this.clientno.toString() != '') {
      this.clientSelected = false;
      this.subscriptionService.getCustomerUnauthorized(this.clientno).subscribe(customer => {
        this.clientnoNotFound = false;
        this.onlyOneResult = true;
        if (customer && customer.userBackendId == this.clientno) {
          this.customer = customer;
          this.clientSelected = true;
        } else {
          this.clientnoNotFound = true;
        }
        this.isLoading = false;
      });
    }
  }

  loadClientNl() {
    this.isLoading = true;
    if (this.zipCode && this.zipCode !== '' && this.houseNumber && this.houseNumber !== '') {
      this.clientSelected = true;
      const body = this.buildAddressListObject();
      body.address.zipcode = this.zipCode;
      body.address.houseno = this.houseNumber;
      this.subscriptionService.fillCustomerUnauthorized(body).subscribe(customers => {
        this.clientnoNotFound = false;
        if (customers && customers.length > 0) {
          this.onlyOneResult = customers.length === 1;
          if (customers.length === 1) {
            this.customer = customers[0];
            this.validateButtonClick.emit(this.customer.userBackendId);
          } else {
            // es kam mehr als einer Zurück, daher müssen wir noch mal die Zusatzfelder abfragen!
            this.customers = customers;
            //this.clientnoNotFound = true;
          }
        } else {
          this.clientnoNotFound = true;
        }
        this.isLoading = false;
      });
    } else {
      this.clientSelected = false;
    }
  }

  private buildAddressListObject(): AddressListItem {
    const address = new Address(null, null, null, null, null, null, null, null, null, null, null, null);
    const person = new Person(null, null, null, null, null, null, null, null)
    const addressListItem = new AddressListItem(null, address, person, null);
    return addressListItem;
  }

  validateClientAndEmit() {
    if (this.validationIsActive) {
      if (this.customer) {
        const validFactors = this.validateCustomer(this.customer);
        const valid = this.isEveryFactorValid(validFactors);
        if (valid) {
          this.validateButtonClick.emit(this.clientno);
          this.inputInvalid = false;
        } else {
          this.inputInvalid = true;
        }
      } else if (this.customers && !this.onlyOneResult) {
        // wir sind wohl in NL und müssen alle Adressen auf die ValidFactors prüfen..
        let foundValidCustomer: boolean = false;
        this.customers.forEach(customer => {
          // nun jeden Eintrag überprüfen
          const validFactors = this.validateCustomer(customer);
          const valid = this.isEveryFactorValid(validFactors);
          if (!foundValidCustomer) {
            foundValidCustomer = valid;
          }
          if (valid) {
            this.validateButtonClick.emit(customer.userBackendId);
            this.inputInvalid = false;
          }
        });
        if (!foundValidCustomer) {
          this.inputInvalid = true;
        }
      } else {
        this.inputInvalid = true;
      }
    } else {
      // TODO Probleme im NL Mode! Dann ist Clientno nicht gesetzt!
      this.validateButtonClick.emit(this.clientno);
    }
  }

  private isEveryFactorValid(validFactors: boolean[]): boolean {
    if (validFactors.every((value) => value == true)) {
      return true;
    } else {
      return false;
    }
  }

  validateCustomer(customer: AddressListItem): boolean[] {
    let validFactors: boolean[] = [];
    validFactors.splice(0, validFactors.length);
    for (let i = 0; i < this.validationFactors.length; i++) {
      validFactors.push(this.customerInformationIsValid(this.validationFactors.at(i), customer));
    }
    return validFactors;
  }

  customerInformationIsValid(validationFactor: string, customer: AddressListItem): boolean {
    let customerValue: any;

    if (customer.person[validationFactor] != undefined) {
      customerValue = customer.person[validationFactor];
    } else if (customer.address[validationFactor] != undefined) {
      customerValue = customer.address[validationFactor];
    }

    if (validationFactor === 'houseno') {
      customerValue = this.houseNoPipe.transform(customerValue);
    }
    if(this.defaultCountry === 'NL' && validationFactor.endsWith('name')) {
      return customerValue.toString().toUpperCase() === this.validationForm.get(validationFactor).value.toString().toUpperCase();
    } else {
      return customerValue === this.validationForm.get(validationFactor).value;
    }
  }

  validateZipCodeNL(){
    this.zipCode = this.zipCode.toUpperCase();
    let value = this.zipCode.substring(0,4).replace(/[^0-9]*/g, '');
    this.zipCode = value + this.zipCode.substring(4,6).replace(/[^A-Za-z]*/g, '');
    this.zipCodeNlValid = ( /^\d{4}[A-Z]{2}$/g ).test(this.zipCode);
  }

  validateHouseNoNL() {
    this.houseNumber = this.houseNumber.substring(0,5).replace(/[^0-9]*/g, '');
    this.houseNoNlValid = ( /^\d{1,5}$/g ).test(this.houseNumber);
  }
}
