import {Component, EventEmitter, Input, OnInit, Optional, Output, ViewEncapsulation} from '@angular/core';
import {ControlContainer, FormGroup, NgForm} from "@angular/forms";
import {TranslationService} from "../../../../../core/services/translation.service";
import {AddressSearch} from "../../../../../data/models/address-search.model";
import {AddressSearchService} from "../../../../../core/services/address-search.service";

@Component({
  selector: 'app-hup-subscription-address-specifics',
  templateUrl: './hup-subscription-address-specifics.component.html',
  styleUrls: ['./hup-subscription-address-specifics.component.css']
})
export class HupSubscriptionAddressSpecificsComponent {
  @Input() controlGroup: FormGroup;
  @Input() streetNames: string[];
  @Input() isAustriaSelected: boolean;
  @Output() searchAddressEmit: EventEmitter<any> = new EventEmitter();

  addressSearch: AddressSearch = new AddressSearch(null, null, null, null, null);

  constructor(public translationService: TranslationService,
              private addressSearchService: AddressSearchService) {
  }
  replaceInvalidCharactersFromHouseNo() {
    var value = this.controlGroup.get('address.houseNo').value.toUpperCase();
    if(value.length > 10) value = value.slice(0, 10);
    this.controlGroup.get('address.houseNo').setValue(value.replace(/[^A-Za-z0-9\-\/]*/g, ''), {emitEvent: false});
  }

}
